import { useState } from 'react';
import { ToastAttributes, ToastType } from '@components/toast';
import Slide from '@mui/material/Slide';
import { useSnackbar } from 'notistack';
type UseToastState = ToastAttributes;
type UseToastReturnType = [UseToastState, (toast: UseToastState) => void];

/**
 * A custom hook to config the Toast component.
 * NOTE: Use along with the Toast component available at 'src/components/atoms'
 */
const useToast = (type: ToastType = 'auto'): UseToastReturnType => {
  // NB: this state will be removed in the future, need to adjust all of the useToast each component first
  const [toast] = useState<UseToastState>({
    open: false,
    alertTitle: undefined,
    message: undefined,
    type,
    TransitionComponent: Slide
  });
  const {
    enqueueSnackbar
  } = useSnackbar();
  const setStackingToast = (_toast: UseToastState) => {
    enqueueSnackbar({
      variant: _toast.severity,
      persist: _toast.persist,
      autoHideDuration: _toast.autoHideDuration,
      message: _toast.message,
      title: _toast.alertTitle,
      actionIcon: _toast.actionIcon,
      onClose: _toast.onClosedHandler
    });
  };
  return [toast, setStackingToast];
};
export default useToast;